import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { withPreview } from "gatsby-source-prismic";

const Page = styled.div`
  max-width: 1160px;

  margin: 150px auto 78px auto;
  padding: 0 40px;

  @media (max-width: 767px) {
    margin: 76px 0 40px 0;
    padding: 0 20px;
  }

  & a:hover {
    color: #d7490c;
  }
`;

const PamhpletContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 120px;
  grid-row-gap: 60px;

  @media (max-width: 1000px) {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
`;

const Pamphlet = styled.div`
  max-width: 280px;
  margin: 0 auto;

  & h2 {
    font-family: "CaslonDoric-Medium", "Helvetica Neue", "Lucida Grande",
      sans-serif;

    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.06em;

    margin: 15px 0 0 0;
  }

  & div {
    overflow: hidden;
  }

  & img {
    transition: 500ms transform ease;
  }

  &:hover img {
    transform: scale(1.05);
  }

  @media (max-width: 767px) {
    // max-width: 300px;

    margin: 0 auto 40px auto;

    &:last-of-type {
      margin: 0 auto;
    }
  }
`;

const Text = styled.div`
  font-size: 23px;
  line-height: 34px;
  text-align: center;
  letter-spacing: 0.06em;

  margin: 0 auto 86px auto;

  max-width: 920px;

  @media (max-width: 1000px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 24px;

    margin: 0 auto 40px auto;
  }

  @media (max-width: 600px) {
    font-size: 15px;
    line-height: 22px;

    text-align: left;
  }
`;

const PamphletsForCulture = ({ data }) => {
  const allPrismicPamphlets = data.allPrismicPamphlet.edges.map(
    (content, index) => (
      <Pamphlet key={`all_prismic_pamphlets_${index}`}>
        <Link to={`/pamphlets-for-culture/${content.node.uid}`}>
          <div>
            <img
              srcSet={content.node.data.image.fluid.srcSetWebp}
              src={content.node.data.image.fluid.src}
              alt={content.node.data.image.alt}
              loading="lazy"
            />
          </div>
          <h2>{content.node.data.title.text}</h2>
        </Link>
      </Pamphlet>
    )
  );

  return (
    <Page>
      <Helmet title={`Pamphlets For Culture - The Seeking State`} />
      <Text
        dangerouslySetInnerHTML={{
          __html: data.prismicPamphletsForCulture.data.text.html,
        }}
      />

      <PamhpletContainer>{allPrismicPamphlets}</PamhpletContainer>
    </Page>
  );
};

export default withPreview(PamphletsForCulture);

export const query = graphql`
  {
    prismicPamphletsForCulture {
      data {
        text {
          html
        }
      }
    }
    allPrismicPamphlet(sort: { order: ASC, fields: data___title___html }) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            image {
              url
              alt
              fluid {
                srcSetWebp
                src
              }
            }
          }
        }
      }
    }
  }
`;
